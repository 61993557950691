@font-face {
  font-family: 'Baloo';
  src: url('../fonts/Baloo-Regular.eot');
  src: url('../fonts/Baloo-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Baloo-Regular.woff2') format('woff2'),
      url('../fonts/Baloo-Regular.woff') format('woff'),
      url('../fonts/Baloo-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('../fonts/NexaBold.eot');
  src: url('../fonts/NexaBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NexaBold.woff2') format('woff2'),
      url('../fonts/NexaBold.woff') format('woff'),
      url('../fonts/NexaBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('../fonts/NexaLight.eot');
  src: url('../fonts/NexaLight.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NexaLight.woff2') format('woff2'),
      url('../fonts/NexaLight.woff') format('woff'),
      url('../fonts/NexaLight.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'BROTHER';
  src: url('../fonts/BROTHER-Bold.eot');
  src: url('../fonts/BROTHER-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/BROTHER-Bold.woff2') format('woff2'),
      url('../fonts/BROTHER-Bold.woff') format('woff'),
      url('../fonts/BROTHER-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

*{
  margin:0px;
	padding:0px;
	border:none;
	outline:none;
	font-size: 100%;
}
body {
 
  font-family: 'BROTHER';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:16px;
  color: #000000;
	line-height: 30px;
	font-weight:400;
	background:#ebebeb;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
}
code {
  font-family: 'BROTHER';
}


.auto-container{
	position:static;
	max-width:1440px;
	padding:0px 15px;
	margin:0 auto;
}


h1,h2,h3,h4,h5,h6 {
	position:relative;
	font-weight:normal;
	margin:0px;
	background:none;
	line-height:1.2em;
	font-family: 'Booperz';
}

textarea{
	overflow:hidden;	
	resize: none;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

p, .text{
	font-size:16px;
	line-height: 30px;
	font-weight: 400;
	color: #000000;
	margin: 0;
  font-family: 'BROTHER';
}

ul,li{
	list-style:none;
	padding:0px;
	margin:0px;	
}

img{
  display:inline-block;
  max-width:100%;
  height:auto;  
}
::-webkit-input-placeholder{color: inherit;}
::-moz-input-placeholder{color: inherit;}
::-ms-input-placeholder{color: inherit;}

.app-text {
  text-align: center;
  color: #000000;
}
  
.yao-ming-page {
  background-color: #ebebeb;
}
/* width */
::-webkit-scrollbar {
	width: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
	background: #222 ;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
	background: #f5f5f5;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
	background: #f5f5f5;
  }


  @media(max-width: 1500px){
    .auto-container{
      max-width: 1186px;
    }
  }