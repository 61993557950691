.yao-ming-page {
  overflow: hidden;
}

.MuiFormHelperText-root {
  color: white !important;
  font-size: 11px !important;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #ff6c6c;
}

a:hover,
a:focus,
a:visited {
  text-decoration: none !important;
  outline: none;
  color: rgba(75, 34, 11, 0.8);
}

.btn-style-one {
  position: relative;
  background-color: transparent;
  color: #fff !important;
  display: inline-block;
  font-size: 25px;
  font-weight: 600;
  border-radius: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  text-align: center;
  line-height: 36px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: transparent;
  border-radius: 15px;
  border: 8px solid #9468c9;
  transition: all 0.3s ease-in-out;
  font-family: 'FONTSPRING DEMO - Universo';
}

.btn-style-one span {
  background-color: #0d0934;
  border: 2px solid #000;
  width: 270px;
  display: block;
  height: 54px;
  line-height: 48px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  font-family: 'FONTSPRING DEMO - Universo';
  font-weight: 900;
  text-shadow: 3px 3px 0 #034c4f;
}

.btn-style-one:hover {
  border: 8px solid #0d0934;
}

.btn-style-one:hover span {
  background-color: #9468c9;
}

.btn-style-two {
  position: relative;
  background-color: #b6673c;
  color: #fff !important;
  display: inline-block;
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 35px;
  transition: all 0.3s ease-in-out;
}

.btn-style-two:hover {
  background-color: #df956d;
}



.yao-ming-page .sec-title {
  position: relative;
  text-align: left;
  margin-bottom: 100px;
}

.yao-ming-page .sec-title h2 {
  font-size: 100px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Booperz';
  text-shadow: -7px 2px #451a00;
}

.yao-ming-page .sec-title h2 span i {
  font-style: normal;
  font-family: 'Arial';
  font-weight: 900;
}

/* Header Main Css Start*/

.yao-ming-page .header {
  position: relative;
  width: 100%;

  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}

.yao-ming-page .landing-marquee .marquee-container a i {
  color: #F2D4C4;
}

.yao-ming-page .landing-marquee {
  background: #4B220B;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.yao-ming-page .banner-section {
  position: relative;
  background-size: 100%;
  background-position: bottom right;
  background-repeat: no-repeat;
  padding: 150px 0 0;
  height: 99vh;
}
.yao-ming-page .banner-section .video-bg {
  position: absolute;
  top: -113px;
  width: 100%;
  height: 100%;
}

.yao-ming-page .banner-section .video-bg .buy-img {
  width: 100%;
}
.slider-container.marque-area .slick-list p {
  transform: rotate(-8deg);
  font-size: 17px;
  color: #fff;
  text-transform: uppercase;
  font-family: 'Crazy Guy';
}

.yao-ming-page .banner-section .btn-area {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.lazy-label {
  margin-top: -96px;
}

.yao-ming-page .banner-section .banner-image {
  margin-bottom: -84px;
  text-align: center;
  margin-right: -151px;
  position: relative;
}

.yao-ming-page .banner-section .col-lg-5 {
  padding: 0;
}

.yao-ming-page .banner-section .banner-image .z-img {
  position: absolute;
  top: 89px;
  width: 186px;
  left: 245px;
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

.yao-ming-page .banner-section .banner-image .lazy-img {
  position: absolute;
  top: 68px;
  left: 88px;
  width: 200px;
  animation: zoom-in-zoom-out-2 6s ease infinite;
}

.yao-ming-page .banner-section .banner-text {
  position: relative;
  width: 40%;
}
.yao-ming-page .banner-section .dog-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 1.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}
.yao-ming-page .banner-section:hover .dog-bg{
  bottom: 211px;
  opacity: 1;
  visibility: visible;
}
.yao-ming-page .banner-section .banner-left-area {
  position: relative;
  text-align: center;
  width: 100%;
}
.yao-ming-page .banner-section .right-dog {
  position: absolute;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
  top: 173px;
  right: -184px;
}
.yao-ming-page .banner-section .right-dog img {
  width: 400px;
}
.yao-ming-page .banner-section .banner-text h1 {
  position: relative;
  overflow: hidden;
}
.yao-ming-page .banner-section .banner-text .banner-img {
  position: relative;
  margin-top: -131px;
}

.yao-ming-page .banner-section .banner-text .banner-img img {
  width: 367px;
  animation: spin 8s ease infinite;
}

.yao-ming-page .banner-section .stone-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.yao-ming-page .banner-section .stone-bg img {
  width: 100%;
}

.yao-ming-page .banner-section .grass-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.yao-ming-page .coins-sec .ball-img {
  position: absolute;
  left: 37%;
  animation-duration: 8s;
    animation-iteration-count: infinite;
    transform-origin: bottom;
    animation-name: bounce-1;
    animation-timing-function: linear;
}

.yao-ming-page .banner-section .grass-bg img {
  width: 100%;
}
.yao-ming-page .banner-section .sun-img {
  position: absolute;
  right: 400px;
  top: 0;
  animation-name: fadeInBottom ;
  animation-duration: 5s;
    animation-fill-mode: both;
}
.yao-ming-page .banner-section .sun-img img{
  animation: zoom-in-zoom-out-2 6s ease infinite;
}
.cloud-img {
  position: absolute;
  top: 0;
  -webkit-animation: animateCloud 35s linear infinite;
  -moz-animation: animateCloud 35s linear infinite;
  animation: animateCloud 35s linear infinite;

  -webkit-transform: scale(0.75);
  -moz-transform: scale(0.75);
  transform: scale(0.75);
}

.yao-ming-page .banner-section .animation-coin-1 {
  position: absolute;
  top: 43px;
  left: 50px;
  opacity: 0.5;
  width: 92px;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
}
.yao-ming-page .banner-section .animation-coin-2 {
  position: absolute;
  left: 50%;
  opacity: 0.4;
  width: 100px;
  animation: spin 6s ease infinite;
}
.yao-ming-page .banner-section .animation-coin-3 {
  position: absolute;
  right: 26px;
  bottom: 25px;
  width: 100px;
  opacity: 0.3;
  animation: zoom-in-zoom-out-2 6s ease infinite;
}
.yao-ming-page .banner-section .animation-coin-4 {
  position: absolute;
  bottom: 129px;
  left: 250px;
  width: 100px;
  opacity: 0.3;
  animation-name: fadeInBottom ;
  animation-duration: 8s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
}
@-webkit-keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}

@-moz-keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -1000px;
  }

  100% {
    margin-left: 100%;
  }
}




@-webkit-keyframes animateCloud2 {
  0% {
    margin-left: 200px;
  }

  100% {
    margin-left: -200px;
  }
}

@-moz-keyframes animateCloud2 {
  0% {
    margin-left: 200px;
  }

  100% {
    margin-left: -200px;
  }
}

@keyframes animateCloud2 {
  0% {
    margin-left: 200px;
  }

  100% {
    margin-left: -200px;
  }
}



@keyframes fadeInBottom {
  0% {
      opacity: 0;
      transform: translateY(100%);
  }
  100% { opacity: 1 }
}
@keyframes text {
  0% {
    margin-bottom: 10px;
  }

  30% {
    letter-spacing: 25px;
    margin-bottom: 10px;
  }

  85% {
    letter-spacing: 8px;
    margin-bottom: 10px;
  }

  100% {
    margin-bottom: 10px;
  }
}


.yao-ming-page .banner-section .banner-text .buy-area {
  position: relative;
  transition: all 0.3s ease-in-out;
  background-color: #9b5606;
  color: #fff;
  font-size: 20px;
  padding: 10px 20px;
  border-radius: 8px;
  font-family: 'BROTHER';
  margin-top: 21px;
  display: inline-block;
  position: relative;
    overflow: hidden;
}
.yao-ming-page .banner-section .banner-text .buy-area:before {
  content: '';
    position: absolute;
    width: 100px;
    height: 100%;
    background-image: linear-gradient(
        120deg,
        rgba(255,255,255, 0) 30%,
        rgba(255,255,255, .8),
        rgba(255,255,255, 0) 70%
    );
    top: 0;
    left: -100px;
    animation: shine2 3s infinite linear; /* Animation */
}
@keyframes shine2 {
  0% {left: -100px}
  20% {left: 100%}
  100% {left: 100%}
}
.yao-ming-page .banner-section .banner-text .buy-area:hover{
  background-color: #c1d100;
}

.yao-ming-page .banner-section .banner-image img {
  width: 100%;
}

.yao-ming-page .banner-section .banner-text p {
  font-size: 27px;
  font-weight: 400;
  color: #fff;
  line-height: 29px;
  margin: 0;
  font-family: 'BROTHER';
}
.yao-ming-page .banner-section .banner-video video {
  width: 100%;
  height: 1000px;
  object-fit: fill;
}

.yao-ming-page .banner-section .banner-video {
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
}
.yao-ming-page .banner-section .banner-video img {
  width: 100%;
}
.yao-ming-page .banner-section .banner-text .btn-area {
  position: relative;
}

.yao-ming-page .banner-section .banner-text .btn-area ul {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 350px;
}

.yao-ming-page .banner-section .banner-text .btn-area ul li {
  margin-right: 21px;
  margin-bottom: 23px;
}

.yao-ming-page .banner-section .banner-text .btn-area ul li .whitepaper,
.yao-ming-page .banner-section .banner-text .btn-area ul li .tenshi:hover {
  background-color: #F2D4C4;
  color: #4B220B !important;
}

.yao-ming-page .banner-section .banner-text .btn-area ul li .tenshi,
.yao-ming-page .banner-section .banner-text .btn-area ul li .whitepaper:hover {
  background-color: #4B220B;
  color: #F2D4C4 !important;
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}


.yao-ming-page .about-sec .heading-area-left .text-box p {
  font-size: 40px;
  margin-bottom: 0;
  line-height: 58px;
  color: #fff;
  font-family: 'FONTSPRING DEMO - Universo';
}

.yao-ming-page .about-sec .heading-area-right {
  position: relative;
}

.yao-ming-page .about-sec .heading-area-right .heading-box {
  position: relative;
  text-align: left;
  zoom: 1;
  margin-left: -251px;
  margin-bottom: -59px;
  margin-right: 0;
}

.yao-ming-page .about-sec .heading-area-right .heading-box img {
  width: 100%;
}

.yao-ming-page .about-sec .heading-area-right .ring-img {
  zoom: 1;
  left: -157px;
  bottom: 227px;
  position: absolute;
  animation: spin 6s ease infinite;
}

.yao-ming-page .about-sec .text-block .text-box {
  margin-top: 100px;
}

.yao-ming-page .about-sec .text-block .text-box h4 {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.yao-ming-page .about-sec .text-block .text-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #4B220B;
}

.yao-ming-page .toknomics-sec{
  position: relative;
  padding: 104px 0 222px;
  background-position: top center;
  background-size: 100% 100%;
  height: 100vh;
  margin-top: -2px;
  background-image: url('../../static/images/about-bg.png');
}
.yao-ming-page .toknomics-sec .toknomics-area-boxes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 66%;
  margin: 0 auto;
}
.yao-ming-page .toknomics-sec .toknomics-box {
  position: relative;
  text-align: center;
}
.yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-1 {
  padding-bottom: 0;
  top: 34px;
  transform: rotate(-9deg);
  text-align: center;
}
.yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box {
  position: relative;
  min-height: 137px;
}
.yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box h4 {
  font-family: 'BROTHER';
  font-size: 27px;
  color: #fff;
  font-weight: bold;
}

.yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box p {
  font-family: 'Nexa';
  color: #000000;
}

.yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box button {
  background-color: transparent;
  border: none;
}
.yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box .icon {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box .icon img {
  width: 33px;
}
.yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-2 {
  padding-bottom: 0;
  margin-top: 0;
  transform: rotate(-2deg);
  left: -25px;
  top: 11px;
}

.yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-3 {
  margin-top: 0;
  padding-bottom: 0;
  transform: rotate(2deg);
  left: 32px;
  top: 9px;
}
.yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-4 {
  padding-bottom: 0;
  margin-top: 0px;
  top: -15px;
  transform: rotate(8deg);
  left: 12px;
}
.yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-4 .text-box {
  top: 44px;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box .dog-img {
  position: absolute;
  bottom: -100px;
  width: 150px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 1.5s ease-in-out;
}
.yao-ming-page .toknomics-sec .toknomics-box:hover .img-box .dog-img {
  bottom: -30px;
  opacity: 1;
  visibility: visible;
}
.yao-ming-page .toknomics-sec .grass-bg {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box {
  position: relative;
  text-align: center;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box img {
  width: 69%;
  position: relative;
  z-index: 2;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box .text-box {
  position: absolute;
  top: 52px;
  width: 100%;
  padding: 0 39px;
  height: 30%;
  z-index: 99;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box .text-box textarea {
  opacity: 0;
  visibility: hidden;
}
.yao-ming-page .toknomics-sec .table-img-area {
  position: relative;
  z-index: 3;
  margin-top: -37px;
  margin-left: 14px;
}
.yao-ming-page .toknomics-sec .table-img-area .table-img {
  width: 273px;
}
.yao-ming-page .toknomics-sec .table-img-area .dog-img {
  width: 116px;
  position: absolute;
  top: -26px;
  left: -161px;
  opacity: 0;
  transition: all 1.5s ease-in-out;
}
.yao-ming-page .toknomics-sec .table-img-area:hover .dog-img {
  width: 116px;
  position: absolute;
  top: -26px;
  left: 61px;
  opacity: 1;
}


.yao-ming-page .rank-sec {
  position: relative;
  padding: 100px 0;
  background-color: #ebe9eb;
  z-index: 2;
}

.yao-ming-page .rank-sec .col-12 {
  padding: 0;
}

.yao-ming-page .rank-sec .left-rank-area {
  position: relative;
  display: flex;
  align-items: flex-start;
}

.yao-ming-page .rank-sec .left-rank-area .left-img {
  width: 50%;
  text-align: left;
  position: relative;
}

.yao-ming-page .rank-sec .left-rank-area .right-img {
  width: 50%;
  text-align: right;
  position: relative;
  margin-top: 114px;
}
.yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox {
  padding: 50px 23px;
  position: absolute;
  left: 191px;
  top: -34px;
  width: 411px;
  height: 212px;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
  text-align: center;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url('../../static/images/commit-img.png');
}
.yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox p{
  font-size: 16px;
  font-family: 'Nexa';
  font-weight: bold;
}
.stones-img-area {
  position: absolute;
  top: 522px;
  z-index: 1;
}

.stones-img-area .dog-img {
  position: absolute;
  top: 15px;
  left: 50%;
  width: 135px;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: all 1.5s ease-in-out;
}
.stones-img-area:hover .dog-img {
  opacity: 1;
  visibility: visible;
  top: -55px;
}

.stones-img-area img.table-img {
  position: relative;
  z-index: 2;
}



@keyframes sunpulse {
	from {
		box-shadow: 0 0 100px #ff0, 0 0 100px #ff0;
	}
	to {
		box-shadow: 0 0 25px #ff0, 0 0 75px #ff0;
	}
}
.yao-ming-page .toknomics-sec .right-img-area {
  position: absolute;
  right: 30px;
  bottom: 12px;
  z-index: 1;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
}

.yao-ming-page .toknomics-sec .right-img-area .dog-img {
  width: 300px;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box .text-box h4 {
  font-family: 'BROTHER';
  font-size: 20px;
  color: #451a00;
  margin-bottom: 4px;
  letter-spacing: 2px;
}
.yao-ming-page .toknomics-sec .car-animation {
  position: absolute;
  bottom: -49px;
  width: 100%;
  z-index: 9;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box .text-box p {
  font-size: 16px;
  color: #fff;
  font-family: 'BRIGHTSIGHT02';
  letter-spacing: 4px;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box .text-box .icon {
  position: absolute;
  bottom: 0;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  background-color: transparent;
  border: none;
}
.yao-ming-page .toknomics-sec .toknomics-box .img-box .text-box .icon img{
  position: relative;
  width: 36px;
  animation: zoom-in-zoom-out-2 6s ease infinite;
}
.yao-ming-page .toknomics-sec .sec-title {
  padding-left: 0;
  text-align: center;
}
.yao-ming-page .toknomics-sec .bottom-img {
  position: relative;
  text-align: center;
  margin-top: 100px;
}
.yao-ming-page .toknomics-sec .slider-container.marque-area {
  margin-top: -46px;
  margin-bottom: -38px;
}

.yao-ming-page .join-sec {
  position: relative;
  background-position: top center;
  background-size: 100% 100%;
  margin-top: 0;
  height: 100vh;
  padding-top: 54px;
}
.yao-ming-page .join-sec .bottom-img {
  position: absolute;
  left: 0;
  top: -166px;
  width: 100%;
  height: 100%;
}

#txt{
  display:flex;
  align-items:center;
  justify-content:center;
  flex:1;
  font-family:sans-serif;
  letter-spacing:3.5px;
  font-size:3.5rem;
  font-weight:700;
  position:relative;
  transform-style:preserve-3d;
  perspective:100px;
  -webkit-transform-style:preserve-3d;
  -webkit-perspective:100px;
  z-index: 3;
}
#txt>b{
  height:3.5rem;
  box-shadow:0 .4rem .3rem -.3rem #aaa;
  display: block;
  text-align: center;
  font-size: 124px;
  color: #000000;
  text-shadow: 1px -4px #fff;
  font-family: 'Baloo';
  position: relative;
  line-height: 1;
  transform-origin:bottom;
  transform:rotateX(-85deg);
  -webkit-transform-origin:bottom;
  -webkit-transform:rotateX(-85deg);
  animation:getUp 7s infinite;
}
#txt.small-text>b{
  height:3rem;
  box-shadow:0 .4rem .3rem -.3rem #aaa;
  display: block;
  text-align: center;
  font-size: 80px;
  color: #000000;
  text-shadow: 1px -4px #fff;
  font-family: 'Baloo';
  position: relative;
  line-height: 1;
  transform-origin:bottom;
  transform:rotateX(-85deg);
  -webkit-transform-origin:bottom;
  -webkit-transform:rotateX(-85deg);
  animation:getUp 7s infinite;
  margin-top: 60px;
  margin-bottom: 60px;
}
#txt>b:nth-child(2){
  animation-delay:.25s;
}
#txt>b:nth-child(3){
  animation-delay:.5s;
}
#txt>b:nth-child(4){
  animation-delay:.75s;
}
#txt>b:nth-child(5){
  animation-delay:1s;
}
#txt>b:nth-child(6){
  animation-delay:1.25s;
}
#txt>b:nth-child(7){
  animation-delay:1.5s;
}
#txt>b:nth-child(8){
  animation-delay:1.75s;
}
@keyframes getUp{
  10%,50%{
     transform:rotateX(0);
  }
  0%,60%,100%{
     transform:rotateX(-85deg);
  }
}

.yao-ming-page .join-sec .heading-memes-area-left .tree-img {
  position: relative;
  margin-left: -49px;
  z-index: 1;
}
.yao-ming-page .join-sec .heading-memes-area-left .tree-img img{
  width: 407px;
}
.yao-ming-page .join-sec .heading-memes-area-left .tree-img .dof-img {
  position: absolute;
  left: 392px;
  bottom: 0;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
}
.yao-ming-page .coins-sec .tree-left-area {
  position: relative;
}
.yao-ming-page .join-sec .bottom-join {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.yao-ming-page .join-sec .bottom-join img {
  width: 100%;
}
.yao-ming-page .coins-sec .tree-left-area .chartor-area {
  position: relative;
  margin-top: -286px;
  -webkit-animation: animateCloud2 35s linear infinite;
  -moz-animation: animateCloud2 35s linear infinite;
  animation: animateCloud2 35s linear infinite;
}

.yao-ming-page .coins-sec .baby-img {
  position: absolute;
  top: 33%;
  right: 35%;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
}

.yao-ming-page .coins-sec .dog-right-img {
  position: absolute;
  bottom: 126px;
  right: -2px;
}

.yao-ming-page .coins-sec .dog-right-img img {
  width: 600px;
}

.yao-ming-page .join-sec .sec-title {
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
  z-index: 3;
  position: relative;
}
.yao-ming-page .join-sec .sec-title h2 {
  display: block;
  text-align: center;
  font-size: 124px;
  color: #000000;
  text-shadow: 1px -4px #fff;
  font-family: 'Baloo';
  position: relative;
  line-height: 1;
}

.yao-ming-page .join-sec .sec-title h3 {
  display: block;
  text-align: center;
  font-size: 94px;
  text-shadow: 1px -4px #fff;
  font-family: 'Baloo';
  position: relative;
  color: #000;
  line-height: 95px;
  margin-bottom: 25px;
}
.yao-ming-page .join-sec .sec-title p {
  font-family: 'Nexa';
  font-weight: bold;
  font-size: 28px;
}

.yao-ming-page .rank-sec .left-rank-area .right-img img {
  width: 30%;
}
@keyframes anim {
  50%, 80% {
    width: 100%;
  }
}

.yao-ming-page .join-sec .sec-title ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.yao-ming-page .join-sec .sec-title ul li {
  margin: 0 20px;
}

.yao-ming-page .join-sec .sec-title ul li a {
  width: 80px;
  height: 80px;
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #000;
  display: block;
  text-align: center;
  transition: all 0.3s ease-in-out;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  transform-origin: bottom;
  animation-name: bounce-1;
  animation-timing-function: linear;
}

.yao-ming-page .join-sec .sec-title ul li a img {
  width: 41px;
  display: block;
  margin: 10px auto 0;
  transition: all 0.3s ease-in-out;
}
.yao-ming-page .rank-sec .right-img {
  position: absolute;
  top: 311px;
  right: 0;
}
.yao-ming-page .join-sec .sec-title ul li a:hover, 
.yao-ming-page .join-sec .sec-title ul li a.active{
  color: #451a00;
  background-color: #000;
  border: 2px solid #000;
  filter: drop-shadow(3px 4px 1px #000);
}
.yao-ming-page .join-sec .sec-title h2 span {
  color: #fff;
}
@keyframes zoom-in-zoom-out-2 {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}


@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-40px);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-80px);
  }

  100% {
    transform: translateY(0);
  }
}



@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}

.yao-ming-page .safe-sec .bootm-img {
  position: absolute;
  bottom: -8px;
  left: 50%;
  transform: translateX(-50%);
  animation-name: fadeIn;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}



.yao-ming-page .commenty-sec {
  position: relative;
  padding: 100px 0;

}

.yao-ming-page .commenty-sec .text-block .text-box {
  margin-top: 100px;
}

.yao-ming-page .commenty-sec .text-block .text-box h4 {
  font-size: 45px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #4B220B;
}

.yao-ming-page .commenty-sec .text-block .text-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 20px;
  color: #4B220B;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.1, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}


@media(max-width: 1700px) {
  .yao-ming-page .banner-section .right-dog {
    top: 203px;
    right: -87px;
  }
  .yao-ming-page .banner-section .right-dog img {
    width: 309px;
  }
  .yao-ming-page .banner-section .video-bg{
    top: -59px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes{
    width: 59%;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-3{
    left: 27px;
  }
}

@media(max-width: 1610px) {
  .yao-ming-page .banner-section .video-bg .buy-img{
    width: 100%;
    height: 855px;
    object-fit: fill;
  }
  .yao-ming-page .banner-section .video-bg {
    top: -125px;
  }
  .yao-ming-page .banner-section .right-dog {
    top: 107px;
    right: -52px;
  }
  .yao-ming-page .toknomics-sec{
    padding: 39px 0 195px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    width: 56%;
  }
}

@media(max-width: 1500px) {
  .yao-ming-page .banner-section .video-bg {
    top: -73px;
  }
  .yao-ming-page .banner-section .video-bg .buy-img {
    height: 803px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    width: 63%;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox{
    padding: 34px 23px;
    left: 191px;
    top: -34px;
    width: 343px;
    height: 197px;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox p{
    font-size: 14px;
  }
  .yao-ming-page .rank-sec .right-img{
    top: 281px;
    right: -54px;
  }
  .yao-ming-page .rank-sec .right-img img {
    width: 86%;
  }
  .yao-ming-page .join-sec .bottom-img{
    top: 0;
  }
}

@media(max-width: 1410px) {
  .yao-ming-page .banner-section .video-bg .buy-img,
  .yao-ming-page .banner-section {
    height: 824px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box h4{
    font-size: 23px;
  }
  .yao-ming-page .toknomics-sec{
    height: 700px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    width:60%;
    margin-top: -25px;
  }
}

@media(max-width: 1380px) {
  .yao-ming-page .banner-section .video-bg .buy-img, .yao-ming-page .banner-section {
    height: 679px;
  }
  .yao-ming-page .banner-section .right-dog img {
    width: 277px;
  }
  .yao-ming-page .banner-section .right-dog {
    top: 68px;
    right: -52px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    width: 58%;
    margin-top: -25px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box{
    min-height: 121px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box h4{
    font-size: 20px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box p {
    font-size: 14px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-2{
    left: -19px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-3{
    left: 19px;
  }
  #txt>b{
    font-size: 86px;
  }
  #txt.small-text>b{
    font-size: 55px;
    height: 2rem;
  }
  .yao-ming-page .join-sec .heading-memes-area-left .left-img img {
    width: 425px;
  }
  .yao-ming-page .sec-title h2 img {
    width: 43%;
  }
}

@media(max-width: 1300px) {
  .yao-ming-page .toknomics-sec .toknomics-area-boxes{
    width: 54%;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox p {
    font-size: 11px;
    line-height: 19px;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox {
    padding: 32px 23px;
    left: 191px;
    top: -34px;
    width: 269px;
    height: 197px;
  }
  .yao-ming-page .rank-sec .right-img img {
    width: 66%;
  }
  .yao-ming-page .rank-sec .right-img {
    top: 281px;
    right: -135px;
  }
  .yao-ming-page .join-sec{
    height: 700px;
  }
  .yao-ming-page .join-sec .sec-title ul li a{
    width: 60px;
    height: 60px;
  }
}

@media(max-width: 1199px) {
  .yao-ming-page .banner-section .video-bg {
    top: 0;
  }
  .yao-ming-page .banner-section .video-bg .buy-img, .yao-ming-page .banner-section {
    height: 541px;
  }
  .yao-ming-page .banner-section .right-dog img {
    width: 190px;
  }
  .yao-ming-page .banner-section .right-dog {
    top: 63px;
    right: 13px;
  }
  .yao-ming-page .toknomics-sec {
    height: 612px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    width: 50%;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box h4 {
    font-size: 15px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box p {
    font-size: 12px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box {
    min-height: 95px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-2 {
    left: -19px;
    top: -8px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-3 {
    left: 15px;
    top: -6px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-4{
    top: -38px;
    left: 3px;
  }
  .yao-ming-page .sec-title h2 img {
    width: 31%;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-1{
    top: 11px;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox{
    left: 138px;
        top: -56px;
  }
  .yao-ming-page .rank-sec {
    position: relative;
    padding: 54px 0;
  }
  #txt>b {
    font-size: 67px;
    height: 0.5rem;
  }
  #txt.small-text>b {
    font-size: 36px;
    height: 1rem;
  }
  .yao-ming-page .join-sec .sec-title p{
    font-size: 17px;
  }
  .yao-ming-page .join-sec .sec-title{
    margin-top: -22px;
  }
  .yao-ming-page .rank-sec .right-img {
    top: 246px;
    right: -135px;
  }
}
@media(max-width: 991px) {
  .yao-ming-page .toknomics-sec {
    height: 550px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes{
    margin-top: -52px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box h4 {
    font-size: 11px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box p {
    font-size: 10px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box .icon img {
    width: 20px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box {
    min-height: 87px;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox {
    left: 94px;
    top: -56px;
    width: 269px;
    height: 197px;
    zoom: 0.8;
  }
  .yao-ming-page .rank-sec .right-img {
    top: 191px;
    right: -227px;
  }
  .yao-ming-page .rank-sec .right-img img {
    width: 41%;
  }
  .yao-ming-page .join-sec {
    height: 550px;
  }
}
@media(max-width: 785px) {
  .yao-ming-page .banner-section .video-bg .buy-img, .yao-ming-page .banner-section {
    height: 347px;
  }
  .yao-ming-page .banner-section .right-dog img {
    width: 146px;
  }
  .yao-ming-page .banner-section{
    padding: 78px 0 0;
  }
  .yao-ming-page .toknomics-sec {
    height: 471px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box p {
    font-size: 7px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box h4 {
    font-size: 8px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box {
    min-height: 77px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    margin-top: -82px;
  }
  .yao-ming-page .toknomics-sec .sec-title{
    margin-top: -28px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    margin-top: -55px;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox {
    zoom: 0.6;
    left: 129px;
  }
  #txt>b {
    font-size: 35px;
    height: 1rem;
  }
  #txt.small-text>b {
    font-size: 26px;
    height: 1rem;
  }
  .yao-ming-page .join-sec .sec-title p {
    font-size: 14px;
  }
  .yao-ming-page .join-sec .heading-memes-area-left .left-img img {
    width: 295px;
  }
  .yao-ming-page .rank-sec .right-img {
    top: 147px;
    right: -2px;
  }
  .yao-ming-page .rank-sec .right-img img {
    width: 141px;
  }
  .yao-ming-page .join-sec {
    height: 450px;
  }
}
@media(max-width: 710px){
  .yao-ming-page .join-sec .bottom-img {
    top: 111px;
  }
}
@media(max-width: 610px){
  .yao-ming-page .rank-sec .left-rank-area .right-img{
    margin-top: 38px;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox {
    zoom: 0.5;
    left: 129px;
  }
  .txt.small-text {
    margin-top: -26px;
    margin-bottom: -41px;
  }
  .yao-ming-page .rank-sec .right-img img {
    width: 102px;
  }
}
@media(max-width: 500px) {
  .yao-ming-page .banner-section .video-bg .buy-img, .yao-ming-page .banner-section {
    height: 236px;
  }
  .yao-ming-page .banner-section .right-dog img {
    width: 97px;
  }
  .yao-ming-page .banner-section .right-dog {
    top: 32px;
    right: 13px;
  }
  .yao-ming-page .toknomics-sec {
    height: 299px;
    padding: 0px 0 48px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    width: 53%;
    margin-top: -90px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box h4 {
    font-size: 7px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box p {
    font-size: 5px;
    margin-bottom: 0;
    margin-top: -8px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box {
    min-height: 52px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box .icon img {
    width: 15px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-1 {
    top: 13px;
    left: 4px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-2 {
    left: -6px;
    top: 3px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-3 {
    left: 6px;
    top: 3px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-4 {
    top: -31px;
    left: 3px;
  }
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox {
    zoom: 0.4;
    left: 129px;
  }
  .yao-ming-page .rank-sec .right-img img {
    width: 80px;
  }
  .yao-ming-page .join-sec .bottom-img {
    top: 189px;
  }
  .yao-ming-page .join-sec .sec-title ul li a{
    width: 39px;
        height: 39px;
        padding: 5px;
        line-height: 36px;
  }
  .yao-ming-page .join-sec .sec-title ul li a img{
    margin: 0 auto;
  }
  
}

@media(max-width: 420px) {
  .yao-ming-page .rank-sec .left-rank-area .right-img .overlaybox {
    zoom: 0.3;
    left: 189px;
  }
  .yao-ming-page .join-sec .sec-title p {
    font-size: 10px;
  }
}
@media(max-width: 380px) {
  .yao-ming-page .banner-section .video-bg .buy-img, .yao-ming-page .banner-section {
    height: 158px;
  }
  .yao-ming-page .banner-section .right-dog img {
    width: 70px;
  }
  .yao-ming-page .banner-section .right-dog {
    top: 3px;
    right: 13px;
  }
  .yao-ming-page .toknomics-sec {
    height: 213px;
    padding: 0px 0 48px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes {
    width: 57%;
    margin-top: -124px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box h4 {
    font-size: 5px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box p {
    font-size: 4px;
    margin-bottom: 0;
    margin-top: -17px;
  }
  .yao-ming-page .toknomics-sec .toknomics-area-boxes .text-box {
    min-height: 36px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-1 {
    top: 16px;
    left: 7px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-2 {
    left: -3px;
    top: 9px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-3 {
    left: 2px;
    top: 8px;
  }
  .yao-ming-page .toknomics-sec .toknomics-box.toknomics-box-4 {
    top: -28px;
    left: -2px;
  }
  .yao-ming-page .rank-sec .right-img img { 
    width: 60px;
  }
  .yao-ming-page .join-sec {
    height: 349px;
  }
}